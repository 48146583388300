import {
  ref,
  computed,
  onMounted,
  onUnmounted,
} from '@vue/composition-api'

import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import moment from 'moment'
import store from '@/store'
import router from '@/router'

import ExchangeListStoreModule from './ExchangeListStoreModule'

export default function () {
  const APP_STORE_MODULE_NAME = 'app-exchange-list'

  const toast = useToast()
  const makeToast = (variant, title, text) => toast({
    component: ToastificationContent,
    props: {
      title,
      text,
      variant,
      icon: 'AlertTriangleIcon',
    },
  })
  const routerQuery = router.history.current.query
  const initialSearchParams = Object
    .keys(routerQuery)
    .reduce((previoutValue, currentValue) => {
      if (['page', 'size', 'total'].includes(currentValue)) {
        return previoutValue
      }
      if (currentValue === null || currentValue === '') {
        return previoutValue
      }
      if (typeof routerQuery[currentValue] === 'string') {
        return {
          ...previoutValue,
          [currentValue]: [routerQuery[currentValue]],
        }
      }
      return {
        ...previoutValue,
        [currentValue]: routerQuery[currentValue],
      }
    },
    {})
  const searchParams = ref({
    page: parseInt(routerQuery.page, 10) || 1,
    size: parseInt(routerQuery.size, 10) || 20,
    total: parseInt(routerQuery.total, 10) || 0,
    ...initialSearchParams,
  })

  const replaceRouterQuery = () => {
    router.replace({
      query: { ...searchParams.value },
    }).catch(() => {})
  }

  const categoryOptions = [
    {
      label: '상태',
      value: 'STATE',
      paramName: 'state',
      format: 'checkbox',
      options: [
        { text: '대기', value: 'APPLY' },
        { text: '완료', value: 'DONE' },
        { text: '취소', value: 'CANCEL' },
      ],
    },
    {
      label: 'inf_idx',
      value: 'inf_idx',
      paramName: 'inf_idx',
    },
    {
      label: 'adv_idx',
      value: 'adv_idx',
      paramName: 'adv_idx',
    },
    {
      label: '이름',
      value: 'NAME',
      paramName: 'name',
    },
    {
      label: '멤버타입',
      value: 'member_type',
      paramName: 'member_type',
      format: 'checkbox',
      options: [
        { text: 'INF', value: 'INF' },
        { text: 'ADV', value: 'ADV' },
      ],
    },
    {
      label: '신청일자',
      value: 'CREATED_AT',
      paramName: 'created_at',
      format: 'date',
    },
    {
      label: '승인일자',
      value: 'CREATED_AT',
      paramName: 'created_at',
      format: 'date',
    },
  ]

  const tableColumns = [
    { key: 'idx' },
    { key: 'member_type', label: '멤버타입' },
    { key: 'member', label: '멤버' },
    { key: 'exchange_point', label: '신청금액' },
    { key: 'point_deposit', label: '잔여금액' },
    { key: 'state', label: '상태' },
    { key: 'action', label: 'action' },
    { key: 'created_at', label: '신청일자', formatter: val => (moment(val).isValid() ? moment(val).format('YYYY-MM-DD HH:mm:ss') : null) },
    { key: 'exchange_at', label: '승인일자', formatter: val => (moment(val).isValid() ? moment(val).format('YYYY-MM-DD HH:mm:ss') : null) },
  ]
  const items = ref([])
  const totalRows = ref(parseInt(searchParams.value.total, 10))
  const perPage = ref(parseInt(searchParams.value.size, 10) ?? 20)
  const currentPage = computed(() => parseInt(searchParams.value.page, 10))
  const isBusy = ref(false)

  const fetchItems = () => {
    isBusy.value = true
    store
      .dispatch(`${APP_STORE_MODULE_NAME}/fetchPointExchangeList`, searchParams.value)
      .then(response => {
        const { page_info, data } = response.data
        items.value = data
        totalRows.value = page_info.total_count
        searchParams.value.total = page_info.total_count
      })
      .catch(() => {
        makeToast('danger', '목록을 쿼리하는데 실패 했습니다')
      })
      .finally(() => {
        isBusy.value = false
        replaceRouterQuery()
      })
  }

  const search = params => {
    Object.assign(searchParams.value, { ...params, page: 1 })
    fetchItems()
  }

  const changePage = params => {
    Object.assign(searchParams.value, params)
    fetchItems()
  }

  onMounted(() => {
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, ExchangeListStoreModule)
    fetchItems()
  })
  onUnmounted(() => {
    if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
  })

  const selectedIdxList = ref([])
  const onRowSelected = selectedItems => {
    console.log(selectedItems)
    selectedIdxList.value = selectedItems.map(item => item.idx)
  }

  // cancel modal
  const visibleCancelModal = ref(false)
  const turnOnCancelModal = idx => {
    selectedIdxList.value = [idx]
    visibleCancelModal.value = true
  }
  const turnOnManyCancelModal = () => {
    visibleCancelModal.value = true
  }
  const turnOffCancelModal = () => {
    visibleCancelModal.value = false
  }
  const cancelPointExchange = idxList => {
    store
      .dispatch(`${APP_STORE_MODULE_NAME}/cancelPointExchange`, { idx_list: idxList })
      .then(() => {
        makeToast('primary', '출금 취소에 성공 했습니다')
      })
      .catch(() => {
        makeToast('danger', '출금 취소에 실패 했습니다')
      })
      .finally(() => {
        fetchItems()
      })
  }

  // approve modal
  const visibleApproveModal = ref(false)
  const turnOnApproveModal = idx => {
    selectedIdxList.value = [idx]
    visibleApproveModal.value = true
  }
  const turnOnManyApproveModal = () => {
    visibleApproveModal.value = true
  }
  const turnOffApproveModal = () => {
    visibleApproveModal.value = false
  }
  const approvePointExchange = idxList => {
    store
      .dispatch(`${APP_STORE_MODULE_NAME}/approvePointExchange`, { idx_list: idxList })
      .then(() => {
        makeToast('primary', '출금 승인에 성공 했습니다')
      })
      .catch(() => {
        makeToast('danger', '출금 승인에 실패 했습니다')
      })
      .finally(() => {
        fetchItems()
      })
  }

  const downloadFile = (url, name) => {
    const tempLink = document.createElement('a')
    tempLink.style.display = 'none'
    tempLink.href = url
    if (name) {
      tempLink.setAttribute('download', name)
    } else {
      tempLink.setAttribute('download', '')
    }
    document.body.appendChild(tempLink)
    tempLink.click()
    document.body.removeChild(tempLink)
  }

  const downloadExcelPointExchange = () => {
    store
      .dispatch(`${APP_STORE_MODULE_NAME}/excelPointExchange`, searchParams.value)
      .then(response => {
        const file = new File([response.data], 'point_exchange_info.xlsx', {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        })
        const url = window.URL.createObjectURL(file)
        downloadFile(url, 'point_exchange_info.xlsx')
      })
      .catch(() => {
        makeToast('danger', '엑셀 변환에 실패 했습니다')
      })
      .finally(() => {
        fetchItems()
      })
  }

  return {
    searchParams,
    initialSearchParams,
    categoryOptions,
    tableColumns,
    items,
    totalRows,
    perPage,
    currentPage,
    isBusy,

    fetchItems,
    search,
    changePage,

    selectedIdxList,
    onRowSelected,

    // cancel modal
    visibleCancelModal,
    turnOnCancelModal,
    turnOffCancelModal,
    cancelPointExchange,
    turnOnManyCancelModal,

    // approve modal
    visibleApproveModal,
    turnOnApproveModal,
    turnOffApproveModal,
    approvePointExchange,
    turnOnManyApproveModal,

    downloadExcelPointExchange,
  }
}
