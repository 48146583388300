import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    // 환불 내역 리스트
    fetchPointExchangeList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/point-exchange/list/`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    approvePointExchange(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${process.env.VUE_APP_BACKEND_SERVER}/manage/point-exchange/approve/`,
            params,
          )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    cancelPointExchange(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${process.env.VUE_APP_BACKEND_SERVER}/manage/point-exchange/cancel/`,
            params,
          )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    excelPointExchange(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${process.env.VUE_APP_BACKEND_SERVER}/manage/point-exchange/excel/`,
            params,
            { responseType: 'blob' },
          )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
